import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";

// using react-hook-form register
// will at least include {onChange, onBlur, name}
// in propsForInput be sure not to overwrite it

const Input = React.forwardRef(
  ({ label, error, typeToogle, ...propsForInput }, ref) => {
    const [showPwd, setShowPwd] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (typeToogle) inputRef.current.type = showPwd ? "text" : "password";
    }, [showPwd, typeToogle]);

    const onTypeToogle = () => {
      setShowPwd(!showPwd);
    };

    return (
      <div className="input">
        <label className="input__label">{label}</label>
        <input
          className={classNames({
            input__input: true,
            "input__input--error": !!error,
          })}
          ref={(r) => {
            ref(r);
            inputRef.current = r;
          }}
          {...propsForInput}
        ></input>
        {typeToogle && (
          <div
            className={classNames({
              input__eye: true,
              "input__eye--off": showPwd,
            })}
            onClick={onTypeToogle}
          ></div>
        )}
        {error && <span className="input__error">{error?.message}</span>}
      </div>
    );
  }
);

export default Input;

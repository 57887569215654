import React from "react";
// images
import socialLinkedIn from "../assets/btn-social-linkedin-n.png";
import socialInstagram from "../assets/btn-social-instagram-n.png";
import socialTwitter from "../assets/btn-social-twitter-n.png";
import socialYoutube from "../assets/btn-social-youtube-n.png";
import rodotics from "../assets/rodotics.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__contact-container">
        <ContactInfo></ContactInfo>
        <SocialMediaLogos></SocialMediaLogos>
      </div>
      <div className="footer__logo-container">
        <div>
          <img
            src={rodotics}
            alt=""
            onClick={() => window.open("https://roboticscats.com/")}
          />
        </div>
        <CopyRights></CopyRights>
      </div>
    </div>
  );
};

export default Footer;

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <div className="contact-info__title">Get in touch</div>
      <div className="contact-info__content">
        Unit 1602, 16/F., COFCO Tower, 262 Gloucester Road, Causeway Bay, Hong
        Kong
      </div>
      <div className="contact-info__content">info@roboticscats.com</div>
      <div className="contact-info__content">+852 8175 0030</div>
    </div>
  );
};

const SocialMediaLogos = () => {
  return (
    <div className="social-media-logos">
      <img
        src={socialLinkedIn}
        alt=""
        onClick={() =>
          window.open("https://www.linkedin.com/company/roboticscats/")
        }
      />
      <img
        src={socialInstagram}
        alt=""
        onClick={() => window.open("https://www.instagram.com/roboticscats/")}
      />
      <img
        src={socialTwitter}
        alt=""
        onClick={() => window.open("https://twitter.com/roboticscats")}
      />
      <img
        src={socialYoutube}
        alt=""
        onClick={() => window.open("https://www.youtube.com/roboticscats")}
      />
    </div>
  );
};

const CopyRights = () => {
  return (
    <div className="copy-rights">
      <div>© Copyright Robotics Cats 2021. All Rights Reserved.</div>
      <div
        onClick={() => window.open("https://roboticscats.com/terms-of-use/")}
      >
        Terms of Use
      </div>
      <div
        onClick={() => window.open("https://roboticscats.com/privacy-policy/")}
      >
        Privacy Policy
      </div>
      <div onClick={() => window.open("https://roboticscats.com/eula/")}>
        EULA
      </div>
    </div>
  );
};

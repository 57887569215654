import React from "react";
//images
import imgAlert from "../assets/img-alert.png";

const RealTimeAlert = () => {
  return (
    <div className="real-time-alert">
      <img src={imgAlert} alt="" className="real-time-alert__img" />
      <div className="real-time-alert__content">
        <div className="real-time-alert__content__title">
          REAL-TIME WILDFIRE ALERT
        </div>
        <div className="real-time-alert__content__subtitle">
          Fight or flight before too late!
        </div>
        <div className="real-time-alert__content__description">
          LookOut provides alert when a wildfire is detected. You can review the
          alert, and make timely and informed decision.
        </div>
      </div>
    </div>
  );
};

export default RealTimeAlert;

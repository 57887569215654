import React from "react";

const Checkbox = React.forwardRef(({ style, label, ...propsForInput }, ref) => {
  return (
    <div style={style}>
      <label className="checkbox">
        <div className="checkbox__marker">
          <input type="checkbox" ref={ref} {...propsForInput} />
          <span className="checkbox__checker"></span>
        </div>
        <span className="checkbox__info">{label}</span>
      </label>
    </div>
  );
});

export default Checkbox;

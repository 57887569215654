import React from "react";

import rodoticsLogo from "../../assets/rodotics-w.png";

const LayoutWrapper = ({ title, subTitle, children, question }) => {
  return (
    <div className="layout-wrapper">
      <div className="layout-wrapper__bg-layer"></div>
      <div className="layout-wrapper__container">
        <img
          className="layout-wrapper__container__logo"
          src={rodoticsLogo}
          alt=""
        />
        <div className="layout-wrapper__container__title">{title}</div>
        <div className="layout-wrapper__container__subtitle">{subTitle}</div>
        <div className="layout-wrapper__container__content">{children}</div>
        <div className="layout-wrapper__container__question">{question}</div>
        <div className="layout-wrapper__container__footer">
          <div>Copyright © 2021 Roboticscats. All Rights Reserved.</div>
          <div
            onClick={() =>
              window.open("https://roboticscats.com/terms-of-use/")
            }
          >
            Terms of Use
          </div>
          <div
            onClick={() =>
              window.open("https://roboticscats.com/privacy-policy/")
            }
          >
            Privacy Policy
          </div>
          <div onClick={() => window.open("https://roboticscats.com/eula/")}>
            EULA
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutWrapper;

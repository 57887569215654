import React, { useEffect } from "react";
import {
  useWindowScroll,
  useWindowSize,
  useToggle,
  useLockBodyScroll,
} from "react-use";
import classNames from "classnames";
import { navigate } from "gatsby";

const Header = () => {
  const { width } = useWindowSize();
  const { y } = useWindowScroll();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  useLockBodyScroll(isOpenMenu);
  const isMobile = width < 768;
  const isNavBgWhite = isMobile && y > 230;

  useEffect(() => {
    if (!isMobile) {
      setIsOpenMenu(false);
    }
  }, [isMobile]);

  return (
    <div className="header">
      <div
        className={classNames({
          header__navbar: true,
          "header__navbar--white": !isOpenMenu && isNavBgWhite,
          "header__navbar--dark": isOpenMenu,
        })}
      >
        <div
          className={classNames({
            header__navbar__logo: true,
            "header__navbar__logo--black": !isOpenMenu && isNavBgWhite,
          })}
          onClick={() => window.open("https://roboticscats.com/")}
        ></div>
        <div
          className={classNames({
            header__navbar__link: true,
            "header__navbar__link--in-menu": isOpenMenu,
          })}
        >
          <div
            onClick={() =>
              window.open("https://roboticscats.com/ai-cloud-saas/")
            }
          >
            Pricing
          </div>
          <div onClick={() => navigate("/login")}>Login</div>
        </div>
        <button
          className={classNames({
            header__navbar__btn: true,
            "header__navbar__btn--in-menu": isOpenMenu,
          })}
          onClick={() => navigate("/register")}
        >
          Register <span>{` for Free`}</span>
        </button>
        <button
          className={classNames({
            header__navbar__burger: true,
            "header__navbar__burger--black": !isOpenMenu && isNavBgWhite,
            "header__navbar__burger--close": isOpenMenu,
          })}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        ></button>
      </div>
      <div className="header__content">
        <div className="header__content__title">LookOut wildfire detection</div>
        <div className="header__content__subtitle">
          LookOut wildfire detection SaaS (software-as-a-service) empowers video
          surveillance cameras to detect forest fires.
        </div>
      </div>
    </div>
  );
};
export default Header;

import React, { useRef, useEffect } from "react";
import { useToggle } from "react-use";
import classNames from "classnames";

// using react-hook-form register
// will at least include {onChange, onBlur, name}
// in propsForInput be sure not to overwrite it

const Select = React.forwardRef(
  ({ label, children, error, onMenuCloseNotInDom, ...propsForSelect }, ref) => {
    const selectRef = useRef(null);
    const [open, setOpen] = useToggle(false);
    const [isCursorInDom, setIsCursorInDom] = useToggle(false);
    const onArrowClick = () => {
      setOpen(!open);
    };
    const onSelectCallback = () => {
      setOpen(false);
    };
    const childrenWithClickCallBack = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onSelectCallback });
      }
      return child;
    });

    const eventTriggerCloseCursorNotInDom = () => {
      if (!isCursorInDom) {
        setOpen(false);
        onMenuCloseNotInDom?.();
      }
    };
    useEffect(() => {
      // patch for cursor click outside auto close
      window.addEventListener("click", eventTriggerCloseCursorNotInDom);
      return function cleanup() {
        window.removeEventListener("click", eventTriggerCloseCursorNotInDom);
      };
    });

    return (
      <div
        className="select"
        onMouseEnter={() => setIsCursorInDom(true)}
        onMouseOver={() => {
          if (!isCursorInDom) setIsCursorInDom(true);
        }}
        onMouseLeave={() => setIsCursorInDom(false)}
      >
        <label className="select__label">{label}</label>
        <div
          className={classNames({
            select__select: true,
            "select__select--open": open,
            "select__select--error": !!error,
          })}
          onClick={onArrowClick}
        >
          <input
            ref={(r) => {
              ref(r);
              selectRef.current = r;
            }}
            {...propsForSelect}
            // readOnly
          />
        </div>
        {open && (
          <div className="select__pannel">
            <div className="select__pannel__inner">
              {childrenWithClickCallBack}
            </div>
          </div>
        )}
        {error && <span className="select__error">{error?.message}</span>}
      </div>
    );
  }
);

const Option = ({ value, onClick, children, onSelectCallback }) => {
  return (
    <div
      className="select__option"
      onClick={() => {
        onClick?.(value);
        onSelectCallback();
      }}
    >
      {children}
    </div>
  );
};

Select.Option = Option;
export default Select;

import React, { useState } from "react";
import classNames from "classnames";
//images
import icFlagHK from "../assets/ic-flag-hk.png";
import icFlagThailand from "../assets/icon-ic-flag-thailand@2x.png";
import icFlagBrazil from "../assets/ic-flag-brazil@2x.png";
import icFlagUruguay from "../assets/ic-flag-uruguay@2x.png";
import icFlagSouthafrica from "../assets/icon-ic-flag-southafrica@2x.png";
import icFlagIndonesia from "../assets/ic-flag-indonesia@2x.png";

import btnArrowNext from "../assets/btn-arrow-round-next-n.png";
import btnArrowPre from "../assets/btn-arrow-round-pre-n.png";

const TrustedUser = () => {
  return (
    <div className="trusted-user">
      <div className="trusted-user__content">
        <div className="trusted-user__content__title">
          Trusted by our customers
        </div>
        <div className="trusted-user__content__countries">
          <Country image={icFlagHK} name="Hong Kong"></Country>
          <Seperators></Seperators>
          <Country image={icFlagThailand} name="Thailand"></Country>
          <Seperators></Seperators>
          <Country image={icFlagBrazil} name="Brazil"></Country>
          <Seperators></Seperators>
          <Country image={icFlagUruguay} name="Uruguay"></Country>
          <Seperators></Seperators>
          <Country image={icFlagSouthafrica} name="South Africa"></Country>
        </div>
        <FeedBackCarousel></FeedBackCarousel>
      </div>
      <div className="trusted-user__blank"></div>
    </div>
  );
};

export default TrustedUser;

const Country = ({ image, name }) => {
  return (
    <div className="country">
      <img src={image} alt={name} />
      <span className="country__name">{name}</span>
    </div>
  );
};

const Seperators = () => {
  return <div className="seperators"></div>;
};

const FeedBackCarousel = () => {
  const fakeData = [
    {
      description:
        "“LookOut is like our own bodyguard. It will send alerts to us if it detects possible wildfires.”",
      name: "Ms. Jojo Chan, Volunteer",
      country: "Big Tree Animal Sanctuary and Adoption Center, Hong Kong",
      icon: icFlagHK,
    },
    {
      description:
        "“LookOut is a monthly subscription-based model. It helps more developing countries to adopt technology to combat forest fires.”",
      name: "Dr. Veerchai Tanpipat, Technical Advisor",
      country: "Royal Forest Department, Thailand",
      icon: icFlagThailand,
    },
    {
      description:
        "“LookOut is a simple and effective wildfire detection solution. Its simplicity is its best feature!”",
      name: "Mr. Felipe Borelli, Director",
      country: "Agro Robotics, Brazil",
      icon: icFlagBrazil,
    },
    {
      description:
        "“LookOut automatically detects early-stage fires. It empowers our traffic management center and to detect and monitor wildfires across the country.”",
      name: "Mr. Jose Adrien, Software Engineer",
      country: "CIEMSA, Uruguay",
      icon: icFlagUruguay,
    },
    {
      description:
        "“LookOut wildfire detection software-as-a-service re-invents wildfire detection. It creates a new product category.”",
      name: "Mr. Paul Redhead, Technical Lead",
      country: "Robotics Cats, South Africa",
      icon: icFlagSouthafrica,
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const total = fakeData.length;
  return (
    <div className="feed-back-carousel">
      <div className="feed-back-carousel__card">
        <div className="feed-back-carousel__card__review">
          {fakeData[currentIndex].description}
        </div>
        <div className="feed-back-carousel__card__pannel">
          <Avatar user={fakeData[currentIndex]}></Avatar>
          <div
            onClick={() => setCurrentIndex((total + currentIndex - 1) % total)}
          >
            <img src={btnArrowPre} alt="" />
          </div>
          <div
            onClick={() => setCurrentIndex((total + currentIndex + 1) % total)}
          >
            <img src={btnArrowNext} alt="" />
          </div>
        </div>
      </div>
      <div className="feed-back-carousel__dots">
        {fakeData.map((user, index) => (
          <div
            key={index}
            className={classNames({
              "feed-back-carousel__dots__dot": true,
              "feed-back-carousel__dots__dot--active": index === currentIndex,
            })}
          ></div>
        ))}
      </div>
    </div>
  );
};

const Avatar = ({ user }) => {
  return (
    <div className="avatar">
      <div className="avatar__img">
        <img src={user.icon} alt="" />
      </div>
      <div className="avatar__info">
        <div>{user?.name}</div>
        <div>{user?.country}</div>
      </div>
    </div>
  );
};

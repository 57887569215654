import React from "react";
// images
import icClimate from "../assets/ic-climate.png";
import icAlert from "../assets/ic-alert.png";
import icRisk from "../assets/ic-risk.png";
import icMonitoring from "../assets/ic-monitoring.png";

const WhyYouNeed = () => {
  return (
    <div className="why-you-need">
      <div className="why-you-need__title">WHY YOU NEED LOOKOUT?</div>
      <div className="why-you-need__subtitle">
        Automatic wildfire detection solution
      </div>
      <div className="why-you-need__info">
        <NeedInfo
          img={icClimate}
          title="Protect Family & Properties"
          info="The earlier we detect a wildfire, the better action can take to control the fire, save lives and assets"
        ></NeedInfo>
        <NeedInfo
          img={icAlert}
          title="Real-Time Alert"
          info="If a wildfire is detected, a real-time alert will be sent to you via ReportFires mobile app, you can see the wildfire image in the app"
        ></NeedInfo>
        <NeedInfo
          img={icRisk}
          title="Quick to Deploy"
          info="No additional hardware to install, it takes a few minutes to set up the wildfire detection software-as-a-service"
        ></NeedInfo>
        <NeedInfo
          img={icMonitoring}
          title="24x7 Monitoring"
          info="Our AI wildfire detection can detect wildfire in both daytime and night-time"
        ></NeedInfo>
      </div>
    </div>
  );
};

export default WhyYouNeed;

const NeedInfo = ({ title, info, img }) => {
  return (
    <div className="need-info">
      <img src={img}></img>
      <div className="need-info__content">
        <div className="need-info__content__title">{title}</div>
        <div className="need-info__content__info">{info}</div>
      </div>
    </div>
  );
};

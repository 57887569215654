import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentRF = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK, I agree"
      cookieName="lookout_cookie_consent"
      expires={180}
      containerClasses="cookie-consent__container"
      buttonWrapperClasses="cookie-consent__btn-wrapper"
      buttonClasses="cookie-consent__btn"
      contentClasses="cookie-consent__content"
      //debug
    >
      Our website use cookies to improve and personalize your experience and to
      display advertisements(if any). Our website may also include cookies from
      third parties like Google Adsense, Google Analytics, Youtube. By using the
      website, you consent to the use of cookies. Learn more about our{" "}
      <span
        onClick={() => window.open("https://roboticscats.com/privacy-policy/")}
      >
        Privacy Policy
      </span>
    </CookieConsent>
  );
};

export default CookieConsentRF;

import React from "react";

import rodoticsLogo from "../../assets/rodotics-w.png";
import icSuccess from "../../assets/ic-msg-sucess.png";

const SuccessLayoutWrapper = ({ title, subTitle, children }) => {
  return (
    <div className="success-layout-wrapper">
      <div className="success-layout-wrapper__bg-layer"></div>
      <div className="success-layout-wrapper__container">
        <img
          className="success-layout-wrapper__container__logo"
          src={rodoticsLogo}
          alt=""
        />
        <img
          className="success-layout-wrapper__container__success"
          src={icSuccess}
          alt=""
        />
        <div className="success-layout-wrapper__container__title">{title}</div>
        <div className="success-layout-wrapper__container__subtitle">
          {subTitle}
        </div>
        <div className="success-layout-wrapper__container__content">
          {children}
        </div>
        <div className="success-layout-wrapper__container__footer">
          <div>Copyright © 2021 Roboticscats. All Rights Reserved.</div>
          <div
            onClick={() =>
              window.open("https://roboticscats.com/terms-of-use/")
            }
          >
            Terms of Use
          </div>
          <div
            onClick={() =>
              window.open("https://roboticscats.com/privacy-policy/")
            }
          >
            Privacy Policy
          </div>
          <div onClick={() => window.open("https://roboticscats.com/eula/")}>
            EULA
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessLayoutWrapper;

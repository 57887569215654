import React from "react";
import classNames from "classnames";

const ProtectForest = () => {
  return (
    <div className="protect-forest">
      <div className="protect-forest__bg">
        <div className="protect-forest__bg__title">
          <span>{`LookOut `}</span>
          help us to protect{" "}
          <br className="protect-forest__bg__title__br--tablet" />
          our forests,{" "}
          <br className="protect-forest__bg__title__br--desktop" />
          our properties,{" "}
          <br className="protect-forest__bg__title__br--tablet" />
          our communities and{" "}
          <br className="protect-forest__bg__title__br--mobile" />
          ecosystems.
        </div>
      </div>
    </div>
  );
};

export default ProtectForest;
